import * as track from '../utils/track';
import {escapeRegExp} from '../utils/stringUtilities';
import {
  getDecimalSeparator,
  formatNumber,
  convertCurrencyToNumericString,
  getCurrencySymbolDetails,
} from '../utils/i18nNumberFormatter';

import {openModal} from './modals'; // eslint-disable-line import/no-cycle

/**
 * Initializes forms
 */
export function initForms() {
  const inputs = document.querySelectorAll('.js-input');
  const plusForms = document.querySelectorAll('.plus-form');
  const contactForms = document.querySelectorAll('.contact-form');

  if (inputs.length) {
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value.trim() !== '') {
        inputs[i].classList.add('is-not-empty');
      }
      inputHandler(inputs[i]);
      if (inputs[i].classList.contains('js-currency-field')) {
        initializeCurrencyInputSymbol(inputs[i]);
        currencyInputHandler(inputs[i]);
      }
    }
  }

  if (plusForms.length) {
    for (let i = 0; i < plusForms.length; i++) {
      submitHandler(plusForms[i]);
    }
  }

  contactForms.forEach((form) => {
    const preformLinks = form.querySelectorAll('.preform-message a');
    preformLinks.forEach((preformLink) => {
      preformLink.addEventListener('click', (event) => {
        event.preventDefault();
        const url = preformLink.getAttribute('href');
        /* eslint-disable babel/camelcase, no-undef */
        gtag('event', 'cta-click', {
          event_category: 'plus upgrade',
          event_label: 'upgrade to shopify plus',
        });
        /* eslint-enable */
        // the following is done this way to get around the bizarro issue which causes the upgrade tests
        // to fail any time anything other than a simple href is added
        // as the link value for this link in the yml :( This hopefully will all be resolved
        // when the contact form and contact customization bootstrapping is refactored.
        window.location = `${url}?utm_source=contactus&utm_medium=pluslaunch&utm_campaign=2021-09-autoupgrades-experiment&utm_content=contactus_header`;
      });
    });
  });

  return false;
}

/**
 * Adds listeners to the input to control the labels
 * @param {Node} input The input to attach the listeners to
 */
function inputHandler(input) {
  input.addEventListener('change', () => {
    const value = input.value;

    if (value.trim() === '') {
      input.classList.remove('is-not-empty');
    } else {
      input.classList.add('is-not-empty');
    }
  });
}

function initializeCurrencyInputSymbol(input) {
  const locale = input.dataset.locale;
  const currencyCode = input.dataset.currency;
  const currencyDetails = getCurrencySymbolDetails(locale, currencyCode);
  const currencyFieldContainer = input.parentNode;
  const currencySymbolElement = currencyFieldContainer.querySelector(
    `.currency-input__symbol--${currencyDetails.position}`,
  );
  currencySymbolElement.textContent = currencyDetails.symbol;
  currencyFieldContainer.classList.add(`currency-input--currency-symbol-${currencyDetails.position}`);
}

function currencyInputHandler(input) {
  const locale = input.dataset.locale;
  const minimumFractionDigits = input.dataset.decimalPlaces || 2;
  const maximumFractionDigits = input.dataset.decimalPlaces || 2;

  // valid character to use in regex pattern
  const decimal = escapeRegExp(getDecimalSeparator(locale));

  // pattern with valid characters that user can enter
  const regex = `[^0-9${decimal}]`;

  // regex pattern to only allow first decimal
  const regexWithOneDecimal = `(${decimal}.*)${decimal}`;

  const digitsRegex = new RegExp(regex, 'g');
  const digitsWithOneDecimal = new RegExp(regexWithOneDecimal, 'g');

  input.addEventListener('input', () => {
    const value = input.value;

    if (value.trim()) {
      input.value = input.value.replace(digitsRegex, '').replace(digitsWithOneDecimal, '$1');
      const numericValue = parseFloat(convertCurrencyToNumericString(input.value, decimal));
      input.setAttribute('data-value', numericValue);
    } else {
      input.setAttribute('data-value', '');
    }
  });

  input.addEventListener('blur', () => {
    const value = convertCurrencyToNumericString(input.value, decimal);

    if (value.trim()) {
      input.value = formatNumber(value, locale, {minimumFractionDigits, maximumFractionDigits});
      const numericValue = parseFloat(convertCurrencyToNumericString(input.value, decimal));
      input.setAttribute('data-value', numericValue);
    } else {
      input.setAttribute('data-value', '');
    }
  });

  if (input.value.trim()) {
    input.value = formatNumber(input.value, locale, {minimumFractionDigits, maximumFractionDigits});
    const numericValue = parseFloat(convertCurrencyToNumericString(input.value, decimal));
    input.setAttribute('data-value', numericValue);
  }
}

/**
 * Adds listeners to handle form submission on forms with a class of "plus-form"
 * @param {Node} form The form to attach the listener to
 */
function submitHandler(form) {
  form.addEventListener('submit', (event) => {
    event.preventDefault();

    const endpoint = form.getAttribute('action');
    const serializedFormData = serializeForm(form);
    const xhr = new XMLHttpRequest();
    const thanksNode = form.getAttribute('data-thanks-node');

    xhr.timeout = 5000;

    xhr.onload = () => {
      // Clear processing flag so new submissions can happen...
      // Delay by 3/4 second so spinner animation doesn't appear to just flash
      setTimeout(() => {
        form.removeAttribute('aria-busy');
      }, 750);

      // Process our return data
      if (xhr.status >= 200 && xhr.status < 300) {
        const responseData = JSON.parse(xhr.response);

        if (responseData.errors) {
          clearFormErrors(form);
          dispatchFailureEvent(form, responseData);

          if (JSON.stringify(responseData.errors) === '{}') {
            // No field-specific errors, show generic error instead
            handleGenericError();
          } else {
            // Handle field-level errors
            handleFieldErrors(form, responseData);
          }
        } else {
          clearFormErrors(form);

          trackSuccess(form, thanksNode || 'PageContainer');
          dispatchSuccessEvent(form, responseData);

          if (responseData.redirect) {
            window.location = responseData.redirect;
            return false;
          }

          if (thanksNode) {
            const thanksHeading = document.querySelector(`#${thanksNode} .thanks-heading`);
            const thanksContent = document.querySelector(`#${thanksNode} .thanks-content`);

            if (responseData.thanks) {
              if (responseData.thanks.heading && thanksHeading) {
                thanksHeading.innerHTML = responseData.thanks.heading;
              }

              if (responseData.thanks.content && thanksContent) {
                thanksContent.innerHTML = responseData.thanks.content;
              }
            }

            openModal(thanksNode, document.body, () => {
              resetForm(form);
            });
          }
        }
      } else {
        clearFormErrors(form);
        handleGenericError();
        dispatchFailureEvent(form);
      }

      return false;
    };

    xhr.onerror = () => {
      window.bugsnagClient.notify(new Error('XHR error'), {
        severity: 'error',
        metaData: {
          'serialized form data': serializedFormData,
        },
      });
      clearFormErrors(form);
      handleGenericError();
    };

    xhr.ontimeout = () => {
      window.bugsnagClient.notify(new Error('XHR timeout'), {
        severity: 'error',
        metaData: {
          'serialized form data': serializedFormData,
        },
      });
      clearFormErrors(form);
      handleGenericError();
    };

    if (!form.getAttribute('aria-busy')) {
      // Set a flag on the form that disables further submissions until the current ajax request is complete...
      form.setAttribute('aria-busy', true);

      xhr.open('POST', `${endpoint}.json`);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send(serializedFormData);
    }
  });
}

/**
 * Serializes all of a form's fields into a querystring
 * @param {Node} form The form to serialize
 */
export function serializeForm(form) {
  const formElements = form.querySelectorAll('input, select, textarea');
  const formValues = [];

  for (let i = 0; i < formElements.length; i++) {
    if (formElements[i].type === 'radio') {
      // Since a group radio buttons all have the same name,
      // only include the value attribute of the radio that's currently checked
      if (formElements[i].checked) {
        formValues.push(`${formElements[i].name}=${encodeURIComponent(formElements[i].value)}`);
      }
    } else {
      formValues.push(`${formElements[i].name}=${encodeURIComponent(formElements[i].value)}`);
    }
  }

  return formValues.join('&');
}

/**
 * Clears error messages from a form
 * @param {Node} form The form to clear errors on
 */
function clearFormErrors(form) {
  const errorFieldWrappers = form.querySelectorAll('.input-wrapper--error');
  const errorMessages = form.querySelectorAll('.message--error');
  const genericError = document.getElementById('GenericFormError');

  if (genericError) {
    genericError.classList.add('hide');
  }

  for (let i = 0; i < errorFieldWrappers.length; i++) {
    errorFieldWrappers[i].classList.remove('input-wrapper--error');
  }

  for (let i = 0; i < errorMessages.length; i++) {
    errorMessages[i].parentNode.removeChild(errorMessages[i]);
  }
}

/**
 * Creates error messages for fields that have errors
 * @param {Node} form The form to show errors for
 * @param {} errorData The object that contains error information
 */
function handleFieldErrors(form, errorData) {
  const errorFields = [];

  for (const field in errorData.errors) {
    // eslint-disable-next-line no-prototype-builtins
    if (errorData.errors.hasOwnProperty(field)) {
      const errorField = form.querySelector(`[name="${errorData.formScope}[${field}]"]`);
      const errorHTML = document.createElement('div');
      const errorHTMLID = `${errorField.id}_message`;

      errorHTML.id = errorHTMLID;
      errorHTML.className = 'message message--error message--size-md message--theme-white message--inline';
      errorHTML.innerHTML = `<div class="message__content"><p class="message__text">${errorData.errors[field].join(
        ', ',
      )}</p></div>`;

      errorField.setAttribute('aria-describedby', errorHTMLID);
      errorField.parentNode.classList.add('input-wrapper--error');
      errorField.parentNode.parentNode.insertBefore(errorHTML, errorField.parentNode.nextSibling);

      errorFields.push(errorField);
    }
  }

  errorFields[0].focus();
}

/**
 * Fires off analytics tracking events upon successful form submission
 * @param {Node} form The form being submitted
 * @param {String} thanksNode The ID of the dom node for the Thanks modal
 */
function trackSuccess(form, thanksNode) {
  const formSuccessEvent = form.getAttribute('data-success-event');
  const linkedInTrackingURL = form.getAttribute('data-linkedin-tracking-url');
  const gtmActivityGroup = form.getAttribute('data-gtm-activity-group');
  const gtmActivityTag = form.getAttribute('data-gtm-activity-tag');
  const gtmEventKey = form.getAttribute('data-gtm-event-key');
  const gtmEventValue = form.getAttribute('data-gtm-event-value');
  const facebookStandardEventName = form.getAttribute('data-fb-standard-event');

  // If there's a LinkedIn tracking pixel URL, add the image pixel to the page
  if (thanksNode && linkedInTrackingURL) {
    track.linkedIn(thanksNode, linkedInTrackingURL);
  }

  // Analytics success events
  if (formSuccessEvent) {
    // Trekkie
    track.trekkie(formSuccessEvent);

    // Facebook
    track.facebook(formSuccessEvent);

    // Facebook standard event
    track.facebookStandardEvent(facebookStandardEventName);

    // GA
    track.googleAnalytics({
      eventCategory: 'form success',
      eventAction: formSuccessEvent,
    });

    // Bing
    track.bing({
      eventCategory: 'form success',
      eventAction: formSuccessEvent,
    });

    // Twitter
    track.twitter(formSuccessEvent);

    // Yahoo Japan
    track.yahooJp(formSuccessEvent);
  }

  // GTM
  if (gtmActivityGroup && gtmActivityTag) {
    const eventData = gtmEventKey && gtmEventValue ? {[gtmEventKey]: gtmEventValue} : {};

    track.googleTagManager(eventData, gtmActivityGroup, gtmActivityTag);
  }
}

/**
 * Shows a generic error for the form (if one exists) in the case of an api failure, throttling, denylist, etc.
 */
function handleGenericError() {
  const genericError = document.querySelector('#GenericFormError .form-error');

  if (genericError) {
    genericError.classList.remove('is-not-visible');
    genericError.focus();
  }
}

/**
 * Dispatchs an event on the form element when it has returned with a successful response (Status 200).
 * @param {Node} form The form that is posting the data
 * @param {Object} responseData The response object
 */
function dispatchSuccessEvent(form, responseData) {
  const successEvent = new CustomEvent('/form/submit/success', {detail: responseData});
  form.dispatchEvent(successEvent);
}

/**
 * Dispatchs an event on the form element when it has returned with a failure.
 * @param {Node} form The form that is posting the data
 * @param {Object} responseData The response object
 */
function dispatchFailureEvent(form, responseData) {
  const failureEvent = new CustomEvent('/form/submit/failure', {detail: responseData});
  form.dispatchEvent(failureEvent);
}

/**
 * Allows a form to be reset to its original state
 * @param {Node} form The form to reset
 */
export function resetForm(form) {
  const inputs = [...form.querySelectorAll('.js-input')];

  form.reset();
  clearFormErrors(form);

  // Clear processing flag so new submissions can happen (just in case)
  form.removeAttribute('aria-busy');

  inputs.forEach((input) => {
    if (input.value.trim() === '') {
      input.classList.remove('is-not-empty');
    }
  });
}
