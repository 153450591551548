import {debounce, Breakpoints} from '@shopify/marketing-assets';

import {isInViewport} from '../../utils/isInViewport';
import {randomNumber} from '../../utils/randomNumber';
import {userPrefersReducedMotion} from '../../utils/userPrefersReducedMotion';

import starPoints from './starpoints';
import Star from './star';

/**
 * Animates the world map and stars.
 */
export default function initMap() {
  const breakpoints = new Breakpoints();
  const canvas = document.getElementById('stars-canvas');
  const ctx = canvas.getContext('2d');
  let starAnimationTimer;
  let isPlaying = false;
  let forceStop = false;
  const stars = [];

  /**
   * Toggles the play state of the animation (on/off)
   * @param {Boolean} play  Set to true if the map should be played, false if not
   */
  function togglePlayState(play = false) {
    isPlaying = play;

    if (play) {
      // start animation
      starAnimationTimer = setInterval(draw, 15);
      isPlaying = true;
    } else {
      // stop animation
      clearInterval(starAnimationTimer);
      isPlaying = false;
    }
  }

  /**
   * Creats an array of star instances to be placed on the canvas
   */
  function instantiateStars() {
    const map = document.querySelector('.map');
    const color = map.classList.contains('map--theme-dark') ? '#d0f224' : '#1238bf';

    for (const star of starPoints) {
      stars.push(new Star(star[0], star[1], randomNumber(0.1, 2, 2), color, ctx));
    }
  }

  /**
   * Updates the radius of each star, then redraws them on the canvas
   */
  function draw() {
    // Note: Cannot combine loops here, the clearRect() needs to be ran in between udpaetRadius and draw
    for (const star of stars) {
      star.updateRadius();
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (const star of stars) {
      star.draw();
    }
  }

  /**
   * Handles play/pause for the map, and toggles button state.
   */
  function handleMapPlayPause() {
    const playPauseButton = document.querySelector('.js-map__button');

    playPauseButton.addEventListener('click', () => {
      if (playPauseButton.getAttribute('aria-pressed') === 'true') {
        // Play the stars animation
        togglePlayState(true);
        forceStop = false;
        playPauseButton.setAttribute('aria-pressed', 'false');
      } else {
        // Stop the animation
        togglePlayState(false);
        forceStop = true;
        playPauseButton.setAttribute('aria-pressed', 'true');
      }
    });

    return false;
  }

  /**
   * Animates / stops the map is the map wrapper is in / out of view
   */
  function animateMap() {
    if (forceStop || breakpoints.matches('phone')) {
      return false;
    }

    const mapWrapper = document.querySelector('.map-outer-wrapper');

    if (isInViewport(mapWrapper) && !isPlaying) {
      togglePlayState(true);
    } else if (!isInViewport(mapWrapper) && isPlaying) {
      // disable
      togglePlayState();
    }

    return false;
  }

  instantiateStars();

  if (userPrefersReducedMotion()) {
    draw();
  } else {
    window.addEventListener('scroll', debounce(animateMap, 25));
    handleMapPlayPause();
    animateMap();
  }

  return false;
}
