import {userPrefersReducedMotion} from '../utils/userPrefersReducedMotion';
import * as track from '../utils/track';

/**
 * Simple expanding/collapsing section of content
 * @param {Node} element The wrapper for the accordion, including the trigger and content
 * NOTE: Some attributes are set by the Rails helper: `app/helpers/plus_ui_modules/accordion_helper.rb`
 */
export class Accordion {
  constructor(element) {
    this._trigger = element.querySelector('.js-accordion__trigger');
    this._content = document.getElementById(this._trigger.getAttribute('aria-controls'));
    this._expanded = this._trigger.getAttribute('aria-expanded');
    this._trigger.addEventListener('click', () => {
      this.toggle();
    });

    // Google Analytics events
    this._gaEvents = {
      expand: this._trigger.getAttribute('data-accordion-expand-ga-event'),
      collapse: this._trigger.getAttribute('data-accordion-collapse-ga-event'),
    };
  }

  /**
   * Returns true if accordion is expanded
   */
  isExpanded() {
    if (this._trigger.getAttribute('aria-expanded') === 'false') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Toggles the accordion
   */
  toggle() {
    if (this.isExpanded()) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  /**
   * Expands the accordion
   */
  expand() {
    this._content.style.display = 'block';
    this._trigger.setAttribute('aria-expanded', 'true');

    // Wait for display to change
    setTimeout(() => {
      this._content.classList.add('is-visible');
      this._content.setAttribute('aria-hidden', 'false');
    }, 15);

    // Track any custom Google Analytics events associated with this accordion expanding
    if (this._gaEvents.expand) {
      track.googleAnalytics({
        eventCategory: 'accordion',
        eventAction: this._gaEvents.expand,
      });
    }
  }

  /**
   * Collapses the accordion
   */
  collapse() {
    this._content.classList.remove('is-visible');
    this._trigger.setAttribute('aria-expanded', 'false');
    this._content.setAttribute('aria-hidden', 'true');

    if (userPrefersReducedMotion()) {
      completeCollapse.call(this);
    } else {
      this._content.addEventListener('transitionend', completeCollapse.call(this));
    }

    function completeCollapse() {
      this._content.style.display = 'none';
      this._content.removeEventListener('transitionend', completeCollapse);
    }

    // Track any custom Google Analytics events associated with this accordion collapse
    if (this._gaEvents.collapse) {
      track.googleAnalytics({
        eventCategory: 'accordion',
        eventAction: this._gaEvents.collapse,
      });
    }
  }
}
