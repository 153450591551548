/**
 * Analytics utility used to track metrics to different analytics scripts
 */

/**
 * Inserts linkedIn tracking pixel
 * @param {String} domNode The ID of the DOM Element where LinkedIn tracking pixel will be inserted
 * @param {String} linkedInTrackingURL The SRC for the LinkedIn tracking pixel
 */
function linkedIn(domNode, linkedInTrackingURL) {
  document
    .getElementById(domNode)
    .insertAdjacentHTML(
      'beforeend',
      `<img height="1" width="1" style="display:none;" alt="" src="${linkedInTrackingURL}" />`,
    );
}

/**
 * Sends tracking information to Trekkie
 * @param {String} eventData  The tracking data
 */
function trekkie(eventData) {
  if (typeof window.analytics !== 'undefined') {
    window.analytics.track({
      'data-trekkie-event': 'Plus',
      'data-trekkie-action': eventData,
    });
  }
}

/**
 * Sends tracking information to Facebook.
 * Will be deprecated in favor of facebookStandardEvent()
 * @param {String} eventData  The tracking data
 */
function facebook(eventData) {
  if (typeof window.fbq !== 'undefined') {
    window.fbq('trackCustom', eventData);
  }
}

/**
 * Sends tracking information to Facebook
 * This uses standard events and will eventually replace the
 * facebook track function above, which tracks custom events.
 * @param {String} standardEventName  The standard event to track
 */
function facebookStandardEvent(standardEventName) {
  if (typeof window.fbq !== 'undefined' && standardEventName) {
    window.fbq('track', standardEventName);
  }
}

/**
 * Sends tracking information to Twitter
 * @param {String} successEvent  The tracking data
 */
function twitter(successEvent) {
  const twitterContactFormSucces = 'nzup4';
  const twitterEbookFormSuccess = 'nzup3';
  const twitterDriftChatStart = 'o52ls';
  const twitterDriftChatEmailCapture = 'o52lt';
  const twitterWebinarFormSuccess = 'o5b4q';
  let conversionEvent;

  if (successEvent === 'ShopifyPlusContactFormSuccess') {
    conversionEvent = twitterContactFormSucces;
  } else if (successEvent === 'ShopifyPlusEbookFormSuccess') {
    conversionEvent = twitterEbookFormSuccess;
  } else if (successEvent === 'ShopifyPlusDriftChatStart') {
    conversionEvent = twitterDriftChatStart;
  } else if (successEvent === 'ShopifyPlusDriftChatEmailCapture') {
    conversionEvent = twitterDriftChatEmailCapture;
  } else if (successEvent === 'ShopifyPlusWebinarFormSuccess') {
    conversionEvent = twitterWebinarFormSuccess;
  }

  if (typeof window.twttr !== 'undefined' && conversionEvent) {
    // ESLint always wants camelCase variable names here, but twitter requires `tw_sale_amount`
    // eslint-disable-next-line babel/camelcase
    window.twttr.conversion.trackPid(conversionEvent, {tw_sale_amount: 0, tw_order_quantity: 0});
  }
}

/**
 * Sends tracking information to Google Analytics
 * @param {Object} eventData  The tracking data { eventCategory, eventAction, eventLabel }
 */
function googleAnalytics(eventData) {
  // GA
  if (typeof _gaUTracker !== 'undefined') {
    _gaUTracker('send', 'event', eventData); // eslint-disable-line no-undef
  }
}

/**
 * Sends tracking information to Bing
 * @param {Object} eventData  The tracking data { eventCategory, eventAction, eventLabel }
 */
function bing(eventData) {
  window.uetq = window.uetq || [];
  window.uetq.push({ec: eventData.eventCategory, ea: eventData.eventAction});
}

/**
 * Sends tracking information to Yahoo JP
 * @param {Object} eventData  The tracking data { eventCategory, eventAction, eventLabel }
 */
function yahooJp(eventData) {
  // eslint-disable-next-line babel/camelcase, no-undef
  if (utag_data.site_domain === 'www.shopify.jp' && eventData) {
    // eslint-disable-next-line no-undef
    ytag({
      type: 'yss_conversion',
      config: {
        yahoo_conversion_id: '1001221637', // eslint-disable-line babel/camelcase
        yahoo_conversion_label: 'ucreCIzSyYEDELKevZ8o', // eslint-disable-line babel/camelcase
        yahoo_conversion_value: '0', // eslint-disable-line babel/camelcase
      },
    });
  }
}

/**
 * Sends tracking information to Google Tag Manager
 * @param {Object} eventData The mapping variable to track, usually looks like { u41: 'TrackingEvent' }
 * @param {String} activityGroupString The GTM Activity group to track this event to (defaults to Shopify Plus Global Floodlight group)
 * @param {String} activityTagString The GTM Activity tag to tag this event as (defualts to Shopify Plus Global Floodlight tag)
 */
function googleTagManager(eventData, activityGroupString = 'shopi0', activityTagString = 'shopi000') {
  const urlQueryParams = new URLSearchParams(window.location.search.substring(1));
  const shopifyPlusGTMConfig = {
    allow_custom_scripts: true, // eslint-disable-line babel/camelcase
    send_to: `DC-9321158/${activityGroupString}/${activityTagString}+standard`, // eslint-disable-line babel/camelcase
    // u25: full Page URL (including hashes and query params)
    u25: window.location.href,
    // u30: absolute URL
    u30: `${window.location.origin + window.location.pathname}`,
    u33: urlQueryParams.get('utm_campaign') || '',
    u34: urlQueryParams.get('utm_content') || '',
    u35: urlQueryParams.get('utm_medium') || '',
    u36: urlQueryParams.get('utm_source') || '',
    u37: urlQueryParams.get('utm_term') || '',
  };

  if (typeof gtag !== 'undefined') {
    gtag('event', 'conversion', {...shopifyPlusGTMConfig, ...eventData}); // eslint-disable-line no-undef
  }
}

/**
 * Listens for element clicks where the parent has google analystics data attributes
 * (This will not fire if the element that was clicked has the google analystics attributes)
 */
function listenForGoogleAnalyticsEvents() {
  document.body.addEventListener('click', (evt) => {
    const target = evt.target.closest('[data-ga-category], [data-ga-event]');

    // default event listener will fire if target matches
    if (target && evt.target !== target) {
      googleAnalytics({
        eventCategory: target.dataset.gaEvent || target.dataset.gaCategory,
        eventAction: target.dataset.gaAction,
        eventLabel: target.dataset.gaLabel,
        eventValue: target.dataset.gaValue,
      });
    }

    return false;
  });
}

export {
  linkedIn,
  trekkie,
  facebook,
  facebookStandardEvent,
  twitter,
  googleAnalytics,
  bing,
  googleTagManager,
  listenForGoogleAnalyticsEvents,
  yahooJp,
};
