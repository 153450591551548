import {TweenLite} from 'gsap/all';
import * as BezierEasing from 'bezier-easing';

// NOTE: These easing curves match the values in our CSS config:
// /app/assets/stylesheets/_new/config/variables/_transitions.scss
// These values should be kept up to date in both locations if anything changes
const animations = {
  easeIn: BezierEasing(0.9, 0, 1, 0.4),
  easeOut: BezierEasing(0, 0.9, 0.4, 1),
  easeBoth: BezierEasing(0.6, 0, 0.2, 1),
};

/**
 * Set Up Metrics
 * @param {array} metrics The array of elements to animate
 */
export function setupMetrics(metrics = []) {
  if (!metrics.length || !Array.isArray(metrics)) {
    return false;
  }

  metrics.forEach((metric) => {
    const prefix = metric.getAttribute('data-prefix') || '';
    const suffix = metric.getAttribute('data-suffix') || '';
    const separator = metric.getAttribute('data-separator');
    const delimiter = metric.getAttribute('data-delimiter');
    const decimals = metric.getAttribute('data-decimals');

    metric.setAttribute('data-original-value', metric.innerHTML);

    metric.innerHTML = prefix + numberFormat(0, decimals, separator, delimiter) + suffix;
  });

  return false;
}

/**
 * Measure Animations
 * @param {array} metrics The array of elements to measure
 */
export function measureAnimes(metrics = []) {
  if (!metrics.length || !Array.isArray(metrics)) {
    return false;
  }

  metrics.forEach((metric) => {
    // Store current value
    const currentValue = metric.innerHTML;

    // Reset original value first (if applicable)
    if (metric.getAttribute('data-original-value')) {
      metric.innerHTML = metric.getAttribute('data-original-value');
    }

    // Use natural width
    metric.style.width = '';

    // Measure
    const metricWidth = metric.offsetWidth;

    // Replace current value
    metric.innerHTML = currentValue;

    // Set final width
    metric.style.width = `${metricWidth}px`;
  });

  return false;
}

/**
 * Update Animations
 * @param {array} metrics The array of elements to Update
 */
export function updateAnimes(metrics = []) {
  if (!metrics.length || !Array.isArray(metrics)) {
    return false;
  }

  const screenshotMode = document.getElementById('ScreenshotMode');

  metrics.forEach((metric) => {
    const prefix = metric.getAttribute('data-prefix') || '';
    const suffix = metric.getAttribute('data-suffix') || '';
    const separator = metric.getAttribute('data-separator');
    const delimiter = metric.getAttribute('data-delimiter');
    const decimals = metric.getAttribute('data-decimals');
    const endValue = metric.getAttribute('data-number');
    const easingFunctionString = metric.getAttribute('data-animation-curve');
    const animationTime = screenshotMode ? 0 : 1.2;
    const animationDelay = screenshotMode ? 0 : metric.getAttribute('data-animation-delay');
    const count = {val: 0};
    let easingFunction = animations.easeIn;

    if (easingFunctionString === 'easeBoth' || easingFunctionString === 'easeOut') {
      easingFunction = animations[easingFunctionString];
    }

    // See #6492
    const y = suffix.includes('万') || suffix.includes('億') ? 'y="230"' : '';
    const formattedSuffix = `<tspan class="metric__suffix" ${y}>${suffix}</tspan>`;

    TweenLite.to(count, animationTime, {
      val: endValue,
      ease: easingFunction,
      onUpdate: () => {
        metric.innerHTML = `${prefix}${numberFormat(count.val, decimals, separator, delimiter)}${formattedSuffix}`;
      },
      delay: parseFloat(animationDelay),
    });
  });

  return false;
}

/**
 * Number formatting
 * @param {Float} numberValue the numeric value without any unnecessary punctuation
 * @param {Integer} decimals the number of decimal places to format with
 * @param {String} separator the character used to separate decimals form whole numbers
 * @param {String} delimiter the character used to separate thousands, millions, etc.
 */
function numberFormat(numberValue, decimals, separator = '.', delimiter = ',') {
  const parts = numberValue.toFixed(decimals).split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

  return parts.join(separator);
}
