import {Breakpoints} from '@shopify/marketing-assets';

import {userPrefersReducedMotion} from '../utils/userPrefersReducedMotion';

// sets the video play state to finished for users who prefer reduced motion
export default function initLaptopAnimationForReducedMotion() {
  const breakpoints = new Breakpoints();

  if (userPrefersReducedMotion() || breakpoints.matches('phone')) {
    // Similaer to code found in older version of Marketing assets to replace video with image:
    // https://github.com/Shopify/marketing_assets/blob/0b57204054d0438689b655cae9a6d293ac569ed8/app/javascripts/modules/video.js#L79
    const video = document.querySelector('.platform-laptop video');
    const wrapper = document.createElement('div');
    const img = new Image();

    wrapper.classList.add('platform-laptop__video-placeholder-container');
    img.classList.add('.platform-laptop__video-placeholder-image');
    img.setAttribute('src', video.dataset.poster);
    img.setAttribute('alt', video.getAttribute('aria-label'));

    wrapper.appendChild(img);
    video.replaceWith(wrapper);
  }

  return false;
}
