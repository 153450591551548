/**
 * Traps focus inside of an element.
 * @param {Node} elementToTrapFocusInsideOf The element you want to trap focus in.
 * @param {Array} prependElements Array of nodes to add to the beginning of focus order.
 * @param {Array} appendElements Array of nodes to add to the end of the focus order.
 */
export function trapFocus(elementToTrapFocusInsideOf, prependElements = [], appendElements = []) {
  // Find all focusable children in the element
  const focusableElementsString =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
  let focusableElements = elementToTrapFocusInsideOf.querySelectorAll(focusableElementsString);

  // Convert NodeList to Array
  focusableElements = Array.prototype.slice.call(focusableElements);

  // Prepend elements to array if provided
  if (prependElements.length > 0) {
    prependElements.forEach((element) => {
      focusableElements.unshift(element);
    });
  }

  // Append elements to array if provided
  if (appendElements.length > 0) {
    appendElements.forEach((element) => {
      focusableElements.push(element);
    });
  }

  // ID the first and last tab stops
  const firstTabStop = focusableElements[0];
  const lastTabStop = focusableElements[focusableElements.length - 1];

  /**
   * trapKeyboard()
   * Listens for tab/shift + tab and manages focus.
   * @param {Object} event The event object
   */
  function trapKeyboard(event) {
    // Check for tab key press
    if (event.keyCode === 9) {
      // Shift + tab + first tab stop
      if (event.shiftKey && document.activeElement === firstTabStop) {
        event.preventDefault();
        lastTabStop.focus();

        // Just tab
      } else if (document.activeElement === lastTabStop && !event.shiftKey) {
        event.preventDefault();
        firstTabStop.focus();
      }
    }
  }

  // Listen for and trap the keyboard
  document.addEventListener('keydown', trapKeyboard);
}
