/**
 * Returns the width of the scrollbar in px
 * Creates a hidden div, measures it, then removes it
 */
export function getScrollbarWidth() {
  let scrollBarWidth = 0;
  const scrollbox = document.createElement('div');

  scrollbox.style.overflow = 'scroll';
  document.body.appendChild(scrollbox);
  scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
  document.body.removeChild(scrollbox);
  return scrollBarWidth;
}
