import '@shopify/polyfills/base';
import {CookiesNotice, init} from '@shopify/marketing-assets';
import whatInput from 'what-input'; // eslint-disable-line no-unused-vars

import {setupScrollTriggeredAnimations} from './modules/animations';
import {initNavigation} from './modules/navigation';
import {initForms} from './modules/forms';
import {initModals} from './modules/modals';
import {initMessages} from './modules/messages';
import {initChat} from './modules/chat';
import {googleTagManager} from './utils/track';

window.addEventListener('DOMContentLoaded', () => {
  new CookiesNotice();
  init();
  initNavigation();
  initForms();
  initModals();
  initMessages();
  setupScrollTriggeredAnimations();
  initChat();
  googleTagManager();
});
