import {getScrollbarWidth} from './getScrollbarWidth';

const body = document.body;
const nav = document.querySelector('.js-navigation');

/**
 * Toggles a CSS class that freezes scrolling on the document body
 * Used in conjunction with mobile nav and modals
 */
export function toggleScrollFreeze() {
  if (body.classList.contains('freeze-scrolling')) {
    body.classList.remove('freeze-scrolling');
    body.style.marginRight = null;
    if (nav) {
      nav.style.width = null;
    }
  } else {
    body.style.marginRight = `${getScrollbarWidth()}px`;
    body.classList.add('freeze-scrolling');
    if (nav) {
      nav.style.width = `calc(100% - ${getScrollbarWidth()}px)`;
    }
  }
}
