// Utilities to format numbers with awareness to locales

/**
 * A private function that returns the separator character of numbers in a given locale
 * @param {string} locale Locale or language code (ie: en-US, en, de, fr-CA etc.)
 * @param {string} separatorType Returns the character used for the specified separator type in the requested locale
 * @returns
 */
function _getSeparator(locale, separatorType) {
  return new Intl.NumberFormat(locale).formatToParts(1000.1).find((part) => part.type === separatorType).value;
}

/**
 * A function that determines the character of the decimal for a given locale
 * @param {string} locale Locale or language code (ie: en-US, en, de, fr-CA etc.)
 * @returns Returns the character used for the decimal in the requested locale
 */
function getDecimalSeparator(locale) {
  return _getSeparator(locale, 'decimal');
}

/**
 * A function that determines the character of the delimiter of digits for a given locale
 * @param {string} locale Locale or language code (ie: en-US, en, de, fr-CA etc.)
 * @returns Returns the character used for the delimiter that separates groups of digits in the requested locale
 */
function getGroupSeparator(locale) {
  return _getSeparator(locale, 'group');
}

/**
 * A function that provides details about the currency string.
 * @param {string} locale Locale or language code, defaults to the browser's locale
 * @param {string} currencyCode The currency to use, defaults to USD
 * @returns An object with the position of the symbol relative to the digits, as well as the string of the symbol
 */
function getCurrencySymbolDetails(locale = navigator.language, currencyCode = 'USD') {
  const formattedCurrencyNumber = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).formatToParts(1000.1);
  const arrayIndex = formattedCurrencyNumber.map((section) => section.type).indexOf('currency');
  const position = arrayIndex === 0 ? 'left' : 'right';
  const symbol = formattedCurrencyNumber[arrayIndex].value;
  return {position, symbol};
}

/**
 * A function that formats numbers given the locale
 * @param {number} value The numeric value to format
 * @param {string} locale The locale or language code
 * @param {object} options A list of options to pass, refer to the docs for a list of possible options: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#syntax
 * @returns Returns the formatted number as a string
 */
function formatNumber(value, locale, options = {}) {
  return new Intl.NumberFormat(locale, options).format(value);
}

/**
 * A function that formats numeric values to currency given the locale
 * @param {number} value The numeric value to format to currency
 * @param {string} locale The locale or language code, defaults to the browser's locale
 * @param {string} currencyCode The currency to use, defaults to USD
 * @param {number} minimumFractionDigits The minimum number of fraction digits to use, defaults to the locales default if not provided
 * @returns Returns the formatted currency
 */
function formatCurrency(value, locale = navigator.language, currencyCode = 'USD', minimumFractionDigits) {
  const valueToUse = parseInt(minimumFractionDigits, 10) === 0 ? Math.round(value) : value;

  return formatNumber(valueToUse, locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits,
  });
}

/**
 * Strips out the currency symbol and converts the decimal to a `.`
 * so that the number can be casted to a float or integer
 * @param {string} value Currency formatted number
 * @param {string} decimal The decimal character of the currency string
 * @returns Numeric value of the currency
 */
function convertCurrencyToNumericString(value, decimal) {
  const regex = `[^0-9${decimal}]`;
  const digitsRegex = new RegExp(regex, 'g');
  return value.replace(digitsRegex, '').replace(decimal, '.');
}

export {
  getDecimalSeparator,
  getGroupSeparator,
  formatNumber,
  formatCurrency,
  convertCurrencyToNumericString,
  getCurrencySymbolDetails,
};
