import {debounce} from '@shopify/marketing-assets';

import {userPrefersReducedMotion} from '../utils/userPrefersReducedMotion';

import * as Metrics from './metrics';

/**
 * Sets up the page to handle animations on scroll
 */
export function setupScrollTriggeredAnimations() {
  // Create real arrays from the returned NodeLists
  const animes = [...document.querySelectorAll('.anime')];
  const animeDelays = [...document.querySelectorAll('[data-animation-delay]')];
  const metrics = filterMetrics([...document.querySelectorAll('.anime .metric__big-data')]);

  const screenshotMode = document.getElementById('ScreenshotMode');

  let scrollTicking = false;
  let lastKnownScrollY;
  let animeTriggerPoints;

  /**
   * Initialize animations
   */
  function init() {
    if (userPrefersReducedMotion()) {
      // Disable animations
      animes.forEach((value, index) => {
        animes[index].classList.add('js-animate');
      });
    } else {
      // Animate as normal
      setDelayTimes();
      measureAnimes();
      Metrics.setupMetrics(metrics);
      updateScrollPosition();
      window.addEventListener('resize', debounce(measureAnimes, 250));
      window.addEventListener('scroll', debounce(updateScrollPosition, 10));
    }
  }

  /**
   * Sets the delay times of animations for elements that have the data-animations-delay attribute
   */
  function setDelayTimes() {
    animeDelays.forEach((anime) => {
      anime.style.transitionDelay = anime.getAttribute('data-animation-delay');
      anime.addEventListener(
        'transitionend',
        () => {
          // Remove transition delay after transition
          anime.style.transitionDelay = null;
        },
        {once: true},
      );
    });
  }

  /**
   * Measures the trigger points for when animations should start
   */
  function measureAnimes() {
    animeTriggerPoints = [];

    animes.forEach((anime) => {
      const animeTop = anime.getBoundingClientRect().top + window.pageYOffset;
      let triggerPoint = animeTop;

      if (anime.getAttribute('data-animation-trigger-point') !== 'top') {
        if (anime.getAttribute('data-animation-trigger-point') === 'bottom') {
          triggerPoint = animeTop + (parseInt(window.getComputedStyle(anime).height, 10));
        } else {
          // Midpoint (default)
          triggerPoint = animeTop + (parseInt(window.getComputedStyle(anime).height, 10) / 2);
        }
      }

      if (screenshotMode) {
        // Animate everything immediately
        triggerPoint = 1;
      }

      animeTriggerPoints.push(triggerPoint);
    });

    Metrics.measureAnimes(metrics);
  }

  /**
   * Triggers the play action on videos when they are scrolled into view
   * @param {Node} animeContainer The container that has elements animating
   */
  function playVideosOnScroll(animeContainer) {
    const videos = animeContainer.querySelectorAll('.js-play-on-scroll');

    // eslint-disable-next-line @shopify/prefer-early-return
    videos.forEach((video) => {
      // checks if it's the actual video element and not the fallback image
      if (video.tagName === 'VIDEO') {
        video.play();
        // setting the video autoplay attribute to true ensures that the video will
        // autoplay if the video load event happens after the user scrolls to the element
        video.setAttribute('autoplay', 'true');
      }
    });
  }

  /**
   * Handles animating the element(s)
   */
  function updateAnimes() {
    scrollTicking = false;

    const currentScrollTop = lastKnownScrollY;
    const currentScrollBottom = lastKnownScrollY + window.innerHeight;
    let triggeredAnimes = 0;
    let preDelayTime = 0;

    // eslint-disable-next-line @shopify/prefer-early-return
    animeTriggerPoints.forEach((triggerPoint, i) => {
      if (
        currentScrollBottom > triggerPoint &&
        currentScrollTop < triggerPoint &&
        !animes[i].classList.contains('js-animate')
      ) {
        triggeredAnimes++;

        // Activate the anime
        // If 2 or more animes should be triggered at once, and they have a set adjacent-delay time,
        // trigger the animation after a timeout. These adjacent-delay times are cumulative.
        if (triggeredAnimes && animes[i].getAttribute('data-adjacent-delay') !== null) {
          preDelayTime += parseInt(animes[i].getAttribute('data-adjacent-delay'), 10);

          if (screenshotMode) {
            preDelayTime = 0;
          }

          setTimeout(() => {
            animes[i].classList.add('js-animate');
          }, preDelayTime);
        } else {
          animes[i].classList.add('js-animate');

          // Find, and animate metrics & circle graphs...
          const animeMetrics = filterMetrics([
            ...animes[i].querySelectorAll('.metric__big-data, .circle-graph__number'),
          ]);
          Metrics.updateAnimes(animeMetrics);
          playVideosOnScroll(animes[i]);
        }
      }
    });
  }

  /**
   * Ran when the user scrolls and uses requestAnimationFrame for a smoother experience
   */
  function requestScrollTick() {
    if (!scrollTicking) {
      requestAnimationFrame(updateAnimes);
    }

    scrollTicking = true;
  }

  /**
   * Updates the user's scroll position
   */
  function updateScrollPosition() {
    lastKnownScrollY = window.pageYOffset;
    requestScrollTick();
  }

  /**
   * Filters out the metrics that have a value of 1 or less
   * (This will still allow the anime classes to be used without the number counting)
   *
   * @param {Array} metricsArray  The array of metrics
   */
  function filterMetrics(metricsArray) {
    const filteredMetricsArray = metricsArray.filter((metric) => {
      const number = parseFloat(metric.dataset.number);
      return number >= 2;
    });

    return filteredMetricsArray;
  }

  init();
}
