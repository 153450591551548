import {cookieHelper} from '@shopify/marketing-assets';

/**
 * Initializes any dismissable messages on the page.
 */
export function initMessages() {
  const messages = document.getElementsByClassName('js-message--dismissable');

  if (messages.length === 0) {
    return false;
  }

  for (let i = 0; i < messages.length; i++) {
    const closeButton = messages[i].getElementsByClassName('js-message__close-button')[0];
    closeButton.addEventListener('click', closeMessage, false);
    closeButton.message = messages[i];

    if (messages[i].classList.contains('message--announcement')) {
      if (!cookieHelper.get(`hide_announcement_${messages[i].getAttribute('id')}`)) {
        messages[i].classList.add('is-visible');
      }
    }
  }

  return false;
}

/**
 * Closes a message.
 * @param {Object} event The click event from the close button.
 */
function closeMessage(event) {
  event.currentTarget.message.classList.remove('is-visible');
  event.currentTarget.message.classList.add('is-not-visible');

  if (event.currentTarget.message.classList.contains('message--announcement' && 'message--dismissable')) {
    // set a cookie to keep this message from appearing again
    setCookie(event.currentTarget.message.getAttribute('id'));
  }
}

/**
 * Sets a cookie to remember dismissed state of a message.
 * @param {String} messageID The id of the message to dismiss.
 */
function setCookie(messageID) {
  const cookieDate = new Date();
  const cookieName = `hide_announcement_${messageID}`;

  // Expiration in one week
  const cookieDuration = 1000 * 60 * 60 * 24 * 7;

  cookieDate.setTime(cookieDate.getTime() + cookieDuration);

  cookieHelper.set(cookieName, true, {
    expires: cookieDate.toUTCString(),
  });
}
