// String utilities or manipulations that aren't natively supported with JS

/**
 * A function that takes in a string and returns it with special characters escaped
 * Code taken from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 * @param {string} string String to escape
 * @returns Escaped string
 */
function escapeRegExp(string) {
  // $& means the whole matched string
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export {escapeRegExp};
