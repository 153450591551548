import {Breakpoints} from '@shopify/marketing-assets';

import {trapFocus} from '../utils/trapFocus';
import {toggleScrollFreeze} from '../utils/scrollFreeze';
import {userPrefersReducedMotion} from '../utils/userPrefersReducedMotion';
import {getScrollbarWidth} from '../utils/getScrollbarWidth';
import * as track from '../utils/track';

import {resetForm} from './forms'; // eslint-disable-line import/no-cycle

// modalTrigger will always be the element that called the modal or null
let modalTrigger;
// activeModal will be the active modal or null
let activeModal;
// flag that determines if a modal to be opened is replacing an existing active modal
let modalSwap = false;
let scrollPositionY = 0;

const scrollbarWidth = `${getScrollbarWidth()}px`;
const videoModal = document.getElementById('VideoModal');
const breakpoints = new Breakpoints();

/**
 * Initializes any modals/triggers on the page.
 */
function initModals() {
  // Open and close buttons
  const modalButtons = document.querySelectorAll('.js-modal__close-button, [data-modal-id], [data-modal-video-id]');

  if (modalButtons.length === 0) {
    return false;
  }

  for (let i = 0; i < modalButtons.length; i++) {
    modalButtons[i].addEventListener('click', (event) => {
      event.preventDefault();
      triggerModalFromButton(modalButtons[i]);
    });
  }

  return false;
}

/**
 * Triggers a modal from an button.
 * @param {Node} button The button that triggers the modal.
 */
function triggerModalFromButton(button) {
  const modalId = button.getAttribute('data-modal-id');

  // Wistia Video ID
  const modalVideoId = button.getAttribute('data-modal-video-id');

  if (button.classList.contains('js-modal__close-button')) {
    closeModal(modalId);
  } else if (modalVideoId) {
    addVideoToModal(videoModal, modalVideoId, true);
    openModal(videoModal.getAttribute('id'), button);
  } else {
    if (button.getAttribute('data-prefill-modal-form')) {
      prefillModalForm(modalId);
    }
    openModal(modalId, button);
  }
}

/**
 * Opens a modal.
 * @param {String} modalId The ID of the modal to open.
 * @param {Node} lastFocusedElement The DOM node that triggered this modal (or the node that should receive focus once this modal is closed)
 * @param {Function} openCallback A function to run after the modal opens
 */
function openModal(modalId, lastFocusedElement, openCallback) {
  const modal = document.getElementById(modalId);

  // If no element was found, do nothing
  if (!modal) { return; }

  const modalContentOverflow = modal.querySelector('.js-modal__content .js-modal__content-wrapper');
  const modalOpenGAEvent = modal.getAttribute('data-modal-open-ga-event');

  if (activeModal) {
    modalSwap = true;
    activeModal.classList.add('js-replace-close');
    modal.classList.add('js-replace-open');
    closeModal(activeModal.getAttribute('id'));
  } else {
    modalSwap = false;
    modalTrigger = lastFocusedElement;
    document.addEventListener('keydown', handleKeyPress);

    // Store exact scroll position (to be restored on modal close)
    scrollPositionY = window.pageYOffset;
  }

  activeModal = modal;
  trapFocus(activeModal);
  activeModal.classList.add('is-active');

  // Disable smooth scrolling on html element (if present)
  document.documentElement.style.scrollBehavior = 'auto';

  function afterOpen() {
    activeModal.focus();

    if (modalSwap) {
      modalSwap = false;
    }

    activeModal.classList.remove('js-replace-open');
    activeModal.classList.remove('js-replace-close');
    activeModal.removeEventListener('transitionend', afterOpen);

    if (openCallback) {
      openCallback();
    }

    // Track any custom Google Analytics events associated with this modal opening
    if (modalOpenGAEvent) {
      track.googleAnalytics({
        eventCategory: 'modal',
        eventAction: modalOpenGAEvent,
      });
    }

    // Ensure modal content scroll position is at the top
    modalContentOverflow.scrollTop = 0;

    const modalOpenEvent = new CustomEvent('/modal/opened', {detail: activeModal});
    activeModal.dispatchEvent(modalOpenEvent);
  }

  if (userPrefersReducedMotion()) {
    afterOpen();
  } else {
    activeModal.addEventListener('transitionend', afterOpen);
  }

  // setTimout gives the browser time to paint the modal to the page before trying to animate (otherwise, animation doesn't happen)
  // requestAnimationFrame allows the browser to run the animation a bit more efficiently
  setTimeout(() => {
    window.requestAnimationFrame(() => {
      if (!modalSwap) {
        toggleScrollFreeze();
      }
      activeModal.classList.add('js-animate');
      setCloseButtonPosition();
    });
  }, 10);
}

/**
 * Closes a modal.
 * @param {String} modalId The ID of the modal to close.
 * @param {Node} elementToFocusOnClose The DOM node to set focus to once the modal closes
 */
function closeModal(modalId, elementToFocusOnClose = modalTrigger) {
  const modal = document.getElementById(modalId);
  const modalCloseGAEvent = modal.getAttribute('data-modal-close-ga-event');

  // Create an internally scoped variable to determine whether or not we're swapping a modal
  // Variable needs to be scoped internally because the value might change before the afterClose function runs
  const swappingModal = modalSwap;

  if (!swappingModal) {
    toggleScrollFreeze();

    // Track any custom Google Analytics events associated with this modal closing
    if (modalCloseGAEvent) {
      track.googleAnalytics({
        eventCategory: 'modal',
        eventAction: modalCloseGAEvent,
      });
    }
  }

  // Pause videos before closing modal
  if (modal === videoModal) {
    const video = videoModal.querySelector('video');
    if (video) {
      video.pause();
    }
  }

  function afterClose() {
    const modalForms = [...modal.querySelectorAll('form')];
    const driftChat = document.querySelector('body > .drift-frame-controller');

    modal.classList.remove('is-active');
    modal.classList.remove('js-replace-open');
    modal.classList.remove('js-replace-close');

    if (driftChat) {
      driftChat.classList.remove('is-above-modal');
    }

    // Some clean-up functions aren't necessary when swapping modals
    if (!swappingModal) {
      if (elementToFocusOnClose) {
        elementToFocusOnClose.focus();
      }

      document.removeEventListener('keydown', handleKeyPress);

      // Reset state
      setCloseButtonPosition(true);
      modalTrigger = null;
      activeModal = null;

      // Re-enable smooth scrolling on html element
      document.documentElement.style.scrollBehavior = '';
    }

    modal.removeEventListener('transitionend', afterClose);

    modalForms.forEach((form) => {
      resetForm(form);
    });

    // create a custom event to know when a modal is closed
    const modalCloseEvent = new CustomEvent('/modal/closed', {detail: modal});
    modal.dispatchEvent(modalCloseEvent);
  }

  if (userPrefersReducedMotion()) {
    afterClose();
    modal.classList.remove('js-animate');

    // Restore scroll position
    if (!swappingModal) {
      window.scrollTo(0, scrollPositionY);
    }
  } else {
    modal.addEventListener('transitionend', afterClose);

    window.requestAnimationFrame(() => {
      modal.classList.remove('js-animate');

      // Restore scroll position
      if (!swappingModal) {
        window.scrollTo(0, scrollPositionY);
      }
    });
  }
}

/**
 * Checks for escape key press and closes the active modal.
 * @param {Object} event The keypress event.
 */
function handleKeyPress(event) {
  const closeButton = activeModal.querySelector('.modal__close-button');

  if (event.keyCode === 27) {
    if (closeButton.getAttribute('href')) {
      window.location = closeButton.getAttribute('href');
    } else {
      closeModal(activeModal.getAttribute('id'));
    }
  }
}

/**
 * Adds a Wistia video player to the Video Modal with the selected video.
 * @param {Object} embedContainer The container to add the video modal to.
 * @param {String} videoId The Wistia ID of the video to show.
 * @param {Boolean} autoPlay Whether to autoplay the video or not.
 */
function addVideoToModal(embedContainer, videoId, autoPlay) {
  const videoModalContentLayer = embedContainer.querySelector('.js-modal-video-content');
  const videoTemplate = `<div class="wistia_embed wistia_async_${videoId} video-embed__video autoPlay=${autoPlay} controlsVisibleOnLoad=false endVideoBehavior=reset playButton=true playerColor=000000 videoFoam=true"></div>`;

  const videoScript = document.createElement('script');
  videoScript.type = 'text/javascript';
  videoScript.async = true;
  videoScript.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;

  videoModalContentLayer.innerHTML = videoTemplate;
  videoModalContentLayer.appendChild(videoScript);

  // Track video plays through Google Analytics
  window._wq = [];
  window._wq.push({
    id: videoId,
    onReady: (videoAPI) => {
      const playHandler = () => {
        track.googleAnalytics({
          eventCategory: 'Wistia',
          eventAction: 'Video Played',
          eventLabel: `Video ID: ${videoId}`,
        });

        videoAPI.unbind('play', playHandler);
      };

      if (!videoAPI.data.playHandlerBound) {
        videoAPI.bind('play', playHandler);
        videoAPI.data.playHandlerBound = true;
      }
    },
  });

  // Only grab the Wistia API js if it's not already on the page somewhere
  if (!document.getElementById('WistiaAPI')) {
    const wistiaScript = document.createElement('script');
    wistiaScript.type = 'text/javascript';
    wistiaScript.async = true;
    wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    wistiaScript.id = 'WistiaAPI';

    embedContainer.appendChild(wistiaScript);
  }
}

/**
 * Extracts data from fields that will be used to prefill modal forms
 * @param {Array} prefillDataFields An array of fields that have values to prefill the modal form with
 */
function getPrefillData(prefillDataFields) {
  const prefillData = Object.create({});

  /* eslint-disable @shopify/prefer-early-return */
  prefillDataFields.forEach((prefillDataField) => {
    if (prefillDataField.value) {
      const modalFieldId = prefillDataField.getAttribute('data-prefill-modal-field-id');
      Object.defineProperty(prefillData, modalFieldId, {
        value: prefillDataField.value,
        writable: false,
        enumerable: true,
      });
    }
  });
  /* eslint-enable @shopify/prefer-early-return */

  return prefillData;
}

/**
 * Prefills the fields that need to be filled in the modal form
 * @param {Object} prefillData Has the fields and values that will prefill the fields in the modal
 * @param {String} modalId ID of the modal with the form
 */
function prefillModalFormFields(prefillData, modalId) {
  const modal = document.getElementById(modalId);
  const modalForm = modal.getElementsByTagName('form')[0];

  for (const field in prefillData) {
    // eslint-disable-next-line no-prototype-builtins
    if (prefillData.hasOwnProperty(field)) {
      modalForm[field].value = prefillData[field];
      modalForm[field].classList.add('is-not-empty');
    }
  }
}

/**
 * Prefills fields for forms inside modals.
 * @param {String} modalId  The ID of the modal that has the form.
 */
function prefillModalForm(modalId) {
  const prefillDataFields = [...document.querySelectorAll(`[data-prefill-modal-id="${modalId}"]`)];
  const prefillData = getPrefillData(prefillDataFields);

  prefillModalFormFields(prefillData, modalId);
}

/**
 * Sets the position of the close button relative to the scrollbar
 */
function setCloseButtonPosition(reset = false) {
  const modalTopWrapper = activeModal.querySelector('.js-modal__top-wrapper');
  let modalContent = activeModal.querySelector('.js-modal__content');

  // check if mobile then target wrapper
  if (breakpoints.matches('phone')) {
    modalContent = activeModal.querySelector('.js-modal__content-wrapper');
  }

  if (reset) {
    modalTopWrapper.style.right = 0;
  } else if (modalContent.scrollHeight > modalContent.clientHeight) {
    modalTopWrapper.style.right = `${scrollbarWidth}`;
  }
}

export {initModals, openModal, addVideoToModal};
