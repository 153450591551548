import * as Matter from 'matter-js';

import initMap from '../partials/map';
import initLaptopAnimationForReducedMotion from '../partials/platform-laptop';
import {initVideos} from '../modules/videos';
import {isInViewport} from '../utils/isInViewport';
import {userPrefersReducedMotion} from '../utils/userPrefersReducedMotion';
import * as track from '../utils/track'; // eslint-disable-line no-unused-vars

import '../application';

(function init() {
  initVideos();
  smash();
  initMap();
  initLaptopAnimationForReducedMotion();
})();

/**
 * Creates and renders the ball smashing portion of the new enterprise section.
 */
function smash() {
  const canvasElement = document.getElementById('smashCanvas');
  const bodies = [];
  const canvas = {
    width: 720,
    height: 500,
    centerX: 720 / 2,
    borderWidth: 40,
  };
  const engine = Matter.Engine.create();
  const render = Matter.Render.create({
    element: canvasElement,
    engine,
    options: {
      width: canvas.width,
      height: canvas.height,
      background: 'transparent',
      wireframes: false,
      showAngleIndicator: false,
    },
  });
  const flag = document.getElementsByClassName('js-smash-flag')[0];

  /**
   * Creates the ball, blocks, and stage.
   */
  function createBodies() {
    // Add Ball
    bodies.push(
      Matter.Bodies.circle(canvas.width / 2, -500, 75, {
        density: 0.01,
        force: {
          x: 0,
          y: 5,
        },
        friction: 1,
        frictionAir: 0,
        frictionStatic: 5,
        restitution: 0.2,
        render: {
          fillStyle: 'transparent',
          strokeStyle: '#1238bf',
          lineWidth: 3,
        },
      }),
    );

    // Create boxes
    // Left of center
    createBlock(canvas.centerX - 200, canvas.height - canvas.borderWidth, 20, 70);
    createBlock(canvas.centerX - 158, canvas.height - canvas.borderWidth + 15, 40, 40);
    createBlock(canvas.centerX - 115, canvas.height - canvas.borderWidth - 94, 10, 10);
    createBlock(canvas.centerX - 145, canvas.height - canvas.borderWidth - 89, 10, 10);
    createBlock(canvas.centerX - 140, canvas.height - canvas.borderWidth - 71, 20, 20);
    createBlock(canvas.centerX - 110, canvas.height - canvas.borderWidth - 73, 25, 25);
    createBlock(canvas.centerX - 100, canvas.height - canvas.borderWidth, 70, 70);
    createBlock(canvas.centerX - 80, canvas.height - canvas.borderWidth - 48, 140, 20);

    // Right of center
    createBlock(canvas.centerX + 80, canvas.height - canvas.borderWidth - 43, 140, 20);
    createBlock(canvas.centerX + 100, canvas.height - canvas.borderWidth, 60, 60);
    createBlock(canvas.centerX + 110, canvas.height - canvas.borderWidth - 69, 25, 25);
    createBlock(canvas.centerX + 115, canvas.height - canvas.borderWidth - 89, 10, 10);
    createBlock(canvas.centerX + 140, canvas.height - canvas.borderWidth - 66, 20, 20);
    createBlock(canvas.centerX + 145, canvas.height - canvas.borderWidth - 85, 10, 10);
    createBlock(canvas.centerX + 175, canvas.height - canvas.borderWidth - 40, 30, 140);
    createBlock(canvas.centerX + 205, canvas.height - canvas.borderWidth + 20, 20, 20);

    // Floor
    createBlock(canvas.width / 2, canvas.height - (canvas.borderWidth / 2), canvas.width, canvas.borderWidth, {
      isStatic: true,
      render: {
        visible: false,
      },
    });

    // Left wall
    createBlock(canvas.borderWidth / 2, canvas.height / 2, canvas.borderWidth, canvas.height * 2, {
      isStatic: true,
      render: {
        visible: false,
      },
    });

    // Right wall
    createBlock(canvas.width - (canvas.borderWidth / 2), canvas.height / 2, canvas.borderWidth, canvas.height * 2, {
      isStatic: true,
      render: {
        visible: false,
      },
    });

    // Add bodies
    Matter.World.add(engine.world, bodies);
  }

  /**
   * Starts the world engine if the canvas is in the viewport.
   */
  function startSmashing() {
    if (!isInViewport(flag)) {
      return false;
    }

    // Paused smash looks best frozen at 1780ms
    const playDuration = userPrefersReducedMotion() ? 1780 : 5000;

    window.removeEventListener('scroll', startSmashing);
    startPhysicsEngine();

    setTimeout(() => {
      stopPhysicsEngine();
    }, playDuration);

    return false;
  }

  /**
   * Starts the physics engine.
   */
  function startPhysicsEngine() {
    Matter.Runner.run(engine);
    Matter.Render.run(render);
  }

  /**
   * Stops the physics engine.
   */
  function stopPhysicsEngine() {
    Matter.Engine.clear(engine);
    Matter.Render.stop(render);
  }

  /**
   * Helper utility to create blocks. Really only useful for this application of the engine.
   * @param {number} x The block's x position
   * @param {number} y The block's y position
   * @param {number} width The block's width in px
   * @param {number} height The block's height in px
   * @param {Object} options Options to pass to the block
   */
  function createBlock(x, y, width, height, options = {}) {
    bodies.push(
      Matter.Bodies.rectangle(x, y, width, height, {
        friction: 1,
        frictionAir: 0,
        render: {
          fillStyle: 'transparent',
          strokeStyle: '#8d9091',
          lineWidth: 3,
          opacity: 0.7,
        },
        ...options,
      }),
    );
  }

  createBodies();
  Matter.Render.run(render);
  stopPhysicsEngine();
  window.addEventListener('scroll', startSmashing);
}
