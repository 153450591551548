import {Video} from '@shopify/marketing-assets';

import * as track from '../utils/track';

/**
 * Enables the thumbnail actionable element if/when the script loads
 * @param {Node} video The video module container
 */
function enableVideoThumbnail(video) {
  const videoThumbnail = video.querySelector('.js-video__thumbnail');

  videoThumbnail.disabled = false;
}

/**
 * Adds the alternate text for the thumbnail image, leaves this
 * empty so that if the script doesn't load and the image is
 * just decorative
 * @param {Node} video The video module container
 */
function addThumbnailAltText(video) {
  const videoThumbnailImage = video.querySelector('.image');
  const alternateText = videoThumbnailImage.dataset.altText;

  if (videoThumbnailImage.tagName === 'IMG') {
    videoThumbnailImage.alt = alternateText;
  } else {
    videoThumbnailImage.querySelector('img').alt = alternateText;
  }
}

/**
 * Checks if image has been loaded on the page to show/hide the play icon,
 * otherwise the icon would show on the page till the asset loads
 * @param {Node} videoThumbnailImage The video thumbnail image node
 * @param {Node} videoPlayIcon The video play icon element
 */
function checkThumbnailImageLoadStatus(videoThumbnailImage, videoPlayIcon) {
  if (videoThumbnailImage.complete) {
    videoPlayIcon.classList.remove('hide');
  } else {
    videoThumbnailImage.addEventListener('load', () => {
      videoPlayIcon.classList.remove('hide');
    });
  }
}

/**
 * Shows the play icons in the video thumbnails
 * @param {Node} video The video module container
 */
function showPlayIcons(video) {
  const videoThumbnailImage = video.querySelector('img');
  const videoPlayIcon = video.querySelector('.js-video__play-icon');

  checkThumbnailImageLoadStatus(videoThumbnailImage, videoPlayIcon);
}

/**
 * Adds the Wistia player embed to the inline video container
 * @param {Node} videoPlayerContainer The video container element to embed the video into
 * @param {String} videoId The Wista video id
 */
function addVideoToContainer(videoPlayerContainer, videoId) {
  const videoTemplate = `<div class="wistia_embed wistia_async_${videoId} video-embed__video autoPlay=true controlsVisibleOnLoad=false endVideoBehavior=reset playButton=true playerColor=000000 videoFoam=true"></div>`;

  const videoScript = document.createElement('script');
  videoScript.type = 'text/javascript';
  videoScript.async = true;
  videoScript.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;

  videoPlayerContainer.innerHTML = videoTemplate;
  videoPlayerContainer.appendChild(videoScript);

  // Track video plays through Google Analytics
  window._wq = [];
  window._wq.push({
    id: videoId,
    onReady: (videoAPI) => {
      const playHandler = () => {
        track.googleAnalytics({
          eventCategory: 'Wistia',
          eventAction: 'Video Played',
          eventLabel: `Video ID: ${videoId}`,
        });

        videoAPI.unbind('play', playHandler);
      };

      if (!videoAPI.data.playHandlerBound) {
        videoAPI.bind('play', playHandler);
        videoAPI.data.playHandlerBound = true;
      }
    },
  });

  // Only grab the Wistia API js if it's not already on the page somewhere
  if (!document.getElementById('WistiaAPI')) {
    const wistiaScript = document.createElement('script');
    wistiaScript.type = 'text/javascript';
    wistiaScript.async = true;
    wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    wistiaScript.id = 'WistiaAPI';

    videoPlayerContainer.appendChild(wistiaScript);
  }
}

/**
 * Handles the trigger for when inline videos are intended to be played
 * @param {Node} video The video module container
 */
function handleInlineVideo(video) {
  const playVideoThumbnailButton = video.querySelector('.js-video__thumbnail');
  const videoPlayerContainer = video.querySelector('.js-video__player-container');
  const videoId = playVideoThumbnailButton.dataset.videoId;

  playVideoThumbnailButton.addEventListener('click', () => {
    addVideoToContainer(videoPlayerContainer, videoId, playVideoThumbnailButton);
    playVideoThumbnailButton.classList.add('hide--visibility');
    videoPlayerContainer.classList.remove('hide');
  });
}

/**
 * Initialize video modules rendered by the Marketing Assets video helper:
 * https://marketing.shopify.io/tools/components/video
 */
function initMarketingAssetsVideoModules() {
  const marketingAssetsVideos = document.querySelectorAll('.js-ma-video');

  marketingAssetsVideos.forEach((maVideo) => {
    // Init Marketing Assets video
    // eslint-disable-next-line no-new
    new Video(maVideo);
    videoClickHandler(maVideo);
  });
}

/**
 * Toggles play/pause on inline videos
 * @param {Node} video The video element to activate toggling on.
 */
function videoClickHandler(video) {
  video.addEventListener('click', () => {
    playPauseToggle(video);
  });

  // Listen for space and enter presses
  video.addEventListener('keydown', (event) => {
    if (event.keyCode !== 32 && event.keyCode !== 13) {
      return false;
    }

    event.preventDefault();
    playPauseToggle(video);
    return false;
  });
}

/**
 * Toggles play/pause on a video
 * @param {Node} video The video to toggle play/pause on
 */
function playPauseToggle(video) {
  const videoWrapper = video.parentElement;

  if (!videoWrapper) {
    return false;
  }

  // Pause or play
  if (videoIsPlaying(video)) {
    video.pause();
    videoWrapper.classList.add('is-paused');
    videoWrapper.classList.remove('is-playing');
  } else {
    video.play();
    videoWrapper.classList.add('is-playing');
    videoWrapper.classList.remove('is-paused');
  }

  return false;
}

/**
 * Returns true if a video is playing, false if not.
 * @param {Node} video The video element you want to check.
 */
function videoIsPlaying(video) {
  return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
}

/**
 * Initializes the video modules on the page
 */
function initVideos(section = document) {
  const videos = section.querySelectorAll('.js-video');

  videos.forEach((video) => {
    enableVideoThumbnail(video);
    addThumbnailAltText(video);
    showPlayIcons(video);
    if (video.classList.contains('js-video--inline')) {
      handleInlineVideo(video);
    }
  });

  initMarketingAssetsVideoModules();
}

export {initVideos};
