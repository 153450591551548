const starPoints = [
  [989, 482],
  [1027, 482],
  [1027, 469],
  [1004, 464],
  [1000, 458],
  [1006, 456],
  [1008, 450],
  [994, 453],
  [992, 443],
  [1064, 457],
  [1096, 413],
  [1131, 477],
  [1147, 478],
  [1161, 448],
  [1201, 483],
  [1194, 435],
  [1198, 432],
  [1191, 432],
  [1185, 428],
  [1193, 415],
  [1003, 492],
  [997, 494],
  [1017, 519],
  [1150, 424],
  [1154, 421],
  [1139, 400],
  [1170, 414],
  [1185, 407],
  [1185, 401],
  [1179, 384],
  [1187, 380],
  [1170, 369],
  [1176, 364],
  [1218, 381],
  [1219, 386],
  [1254, 391],
  [1258, 392],
  [1256, 395],
  [1265, 386],
  [1258, 382],
  [1182, 562],
  [1169, 598],
  [1163, 601],
  [1190, 604],
  [1192, 560],
  [1200, 655],
  [1220, 638],
  [1167, 720],
  [1171, 721],
  [1277, 741],
  [1293, 760],
  [1313, 744],
  [1316, 746],
  [1305, 720],
  [1334, 712],
  [1393, 805],
  [1400, 803],
  [1421, 787],
  [888, 458],
  [904, 453],
  [908, 449],
  [888, 435],
  [877, 440],
  [874, 438],
  [870, 438],
  [871, 427],
  [853, 424],
  [862, 400],
  [825, 406],
  [809, 399],
  [789, 377],
  [782, 381],
  [780, 425],
  [788, 430],
  [786, 434],
  [817, 439],
  [797, 448],
  [796, 469],
  [1020, 337],
  [943, 282],
  [874, 319],
  [842, 307],
  [838, 311],
  [760, 387],
  [727, 385],
  [719, 375],
  [706, 358],
  [689, 362],
  [686, 364],
  [685, 358],
  [688, 353],
  [718, 312],
  [710, 307],
  [695, 302],
  [698, 299],
  [697, 294],
  [709, 290],
  [741, 261],
  [743, 240],
  [662, 314],
  [681, 310],
  [656, 314],
  [651, 314],
  [642, 293],
  [627, 317],
  [657, 321],
  [651, 324],
  [655, 325],
  [648, 335],
  [664, 330],
  [672, 332],
  [671, 340],
  [640, 379],
  [660, 377],
  [624, 386],
  [625, 390],
  [689, 535],
  [654, 540],
  [691, 540],
  [692, 544],
  [720, 603],
  [782, 676],
  [759, 712],
  [431, 340],
  [360, 329],
  [356, 336],
  [354, 332],
  [350, 332],
  [332, 343],
  [326, 349],
  [362, 357],
  [357, 360],
  [350, 362],
  [351, 367],
  [345, 368],
  [328, 371],
  [339, 381],
  [331, 395],
  [326, 406],
  [307, 404],
  [302, 383],
  [296, 385],
  [297, 378],
  [295, 358],
  [297, 355],
  [307, 419],
  [304, 436],
  [281, 425],
  [274, 423],
  [228, 445],
  [202, 446],
  [193, 440],
  [227, 415],
  [242, 406],
  [209, 395],
  [182, 453],
  [245, 375],
  [187, 366],
  [168, 379],
  [151, 406],
  [145, 402],
  [139, 400],
  [137, 380],
  [126, 369],
  [134, 333],
  [137, 324],
  [143, 321],
  [167, 267],
  [57, 208],
  [62, 202],
  [185, 288],
  [253, 485],
  [242, 490],
  [350, 517],
  [360, 518],
  [368, 518],
  [302, 583],
  [420, 547],
  [433, 561],
  [431, 565],
  [474, 658],
  [436, 677],
  [444, 700],
  [432, 698],
  [430, 717],
  [412, 717],
  [415, 723],
  [405, 741],
  [349, 786],
  [1330, 713],
  [1306, 756],
  [1282, 755],
  [1403, 797],
  [1376, 813],
  [1330, 668],
  [1225, 650],
  [1205, 675],
  [1177, 714],
  [1216, 640],
  [1138, 585],
  [1131, 581],
  [1116, 535],
  [1103, 505],
  [1182, 569],
  [1235, 638],
  [1243, 632],
  [1152, 481],
  [1125, 450],
  [1205, 491],
  [1197, 456],
  [1187, 446],
  [1194, 426],
  [1189, 421],
  [1196, 408],
  [1189, 406],
  [1184, 390],
  [1167, 363],
  [1204, 365],
  [1225, 385],
  [1221, 389],
  [1221, 394],
  [1246, 394],
  [1261, 396],
  [1270, 370],
  [1237, 413],
  [1167, 395],
  [1145, 412],
  [1130, 416],
  [1124, 406],
  [1017, 464],
  [1011, 463],
  [1003, 496],
  [1001, 462],
  [1004, 460],
  [997, 448],
  [972, 453],
  [940, 438],
  [882, 430],
  [871, 434],
  [868, 459],
  [859, 476],
  [839, 473],
  [812, 624],
  [671, 533],
  [663, 534],
  [797, 674],
  [790, 697],
  [741, 716],
  [776, 427],
  [717, 420],
  [767, 376],
  [718, 366],
  [709, 354],
  [704, 353],
  [700, 355],
  [689, 357],
  [673, 363],
  [673, 337],
  [660, 337],
  [658, 334],
  [662, 333],
  [660, 330],
  [659, 325],
  [386, 349],
  [354, 352],
  [350, 353],
  [340, 356],
  [306, 389],
  [299, 389],
  [292, 387],
  [288, 397],
  [264, 366],
  [261, 319],
  [237, 301],
  [218, 309],
  [144, 313],
  [132, 299],
  [130, 276],
  [132, 262],
  [124, 241],
  [188, 222],
  [68, 205],
  [273, 427],
  [228, 422],
  [206, 438],
  [144, 406],
  [133, 397],
  [128, 388],
  [135, 384],
  [126, 361],
  [304, 444],
  [335, 484],
  [368, 522],
  [294, 529],
  [279, 501],
  [221, 480],
  [209, 476],
  [390, 543],
  [461, 628],
  [454, 673],
  [450, 675],
  [390, 682],
  [352, 655],
  [366, 715],
  [416, 719],
  [393, 740],
  [395, 748],
  [320, 341],
  [339, 334],
  [352, 335],
  [343, 363],
  [353, 360],
  [358, 356],
  [360, 363],
  [356, 370],
  [647, 325],
  [652, 320],
  [655, 318],
  [685, 282],
  [705, 284],
  [713, 269],
  [700, 245],
  [745, 259],
  [755, 274],
  [759, 278],
  [770, 272],
  [785, 238],
  [828, 299],
  [846, 308],
  [881, 268],
  [865, 335],
  [926, 324],
  [860, 338],
  [750, 229],
  [737, 227],
  [653, 386],
  [633, 406],
  [968, 417],
  [1158, 388],
  [1165, 379],
  [760, 393],
  [725, 391],
  [721, 392],
  [669, 400],
  [656, 413],
  [596, 468],
  [621, 390],
  [672, 369],
  [701, 363],
  [697, 358],
  [686, 349],
  [674, 343],
  [668, 333],
  [684, 322],
  [653, 337],
  [643, 329],
  [643, 312],
  [646, 306],
  [710, 294],
  [829, 332],
  [838, 304],
  [891, 346],
  [861, 416],
  [882, 446],
  [974, 424],
  [1019, 435],
  [993, 463],
  [1007, 467],
  [1003, 484],
  [1012, 508],
  [1025, 525],
  [1081, 477],
  [1080, 460],
  [1137, 489],
  [1152, 476],
  [1168, 446],
  [1192, 444],
  [1198, 426],
  [1196, 412],
  [1192, 411],
  [1176, 394],
  [1181, 362],
  [1215, 359],
  [1226, 336],
  [1217, 395],
  [1225, 393],
  [1259, 386],
  [1269, 385],
  [1185, 413],
  [1178, 420],
  [1153, 417],
  [1146, 416],
  [1110, 533],
  [1126, 544],
  [1128, 558],
  [1136, 580],
  [1147, 593],
  [1200, 582],
  [1234, 646],
  [1319, 735],
  [1423, 771],
  [1421, 777],
  [1405, 801],
  [1294, 750],
  [885, 453],
  [816, 409],
  [816, 417],
  [786, 424],
  [782, 432],
  [789, 447],
  [708, 523],
  [707, 535],
  [661, 537],
  [792, 451],
  [795, 478],
  [805, 617],
  [779, 679],
  [773, 682],
  [770, 696],
  [842, 475],
  [747, 243],
  [829, 216],
  [1022, 230],
  [599, 237],
  [437, 566],
  [447, 683],
  [389, 757],
  [347, 747],
  [385, 525],
  [364, 523],
  [336, 515],
  [275, 511],
  [208, 486],
  [215, 476],
  [208, 467],
  [198, 447],
  [199, 443],
  [180, 436],
  [162, 421],
  [149, 400],
  [137, 393],
  [131, 384],
  [130, 367],
  [134, 352],
  [131, 347],
  [147, 409],
  [242, 430],
  [222, 417],
  [234, 411],
  [290, 423],
  [295, 429],
  [299, 447],
  [291, 404],
  [317, 408],
  [293, 382],
  [239, 376],
  [350, 371],
  [365, 354],
  [371, 353],
  [368, 356],
  [393, 344],
  [315, 353],
  [231, 320],
  [320, 352],
  [324, 346],
  [328, 344],
  [335, 346],
  [300, 577],
  [352, 479],
  [307, 446],
  [304, 448],
  [297, 443],
  [328, 392],
  [331, 389],
  [339, 377],
  [356, 364],
  [358, 367],
  [363, 361],
  [381, 352],
  [384, 355],
  [396, 351],
  [334, 352],
  [332, 347],
  [343, 340],
  [334, 333],
  [338, 330],
  [437, 336],
  [586, 243],
  [623, 321],
  [646, 319],
  [659, 317],
  [667, 326],
  [654, 332],
  [654, 348],
  [637, 365],
  [660, 354],
  [678, 363],
  [670, 372],
  [651, 380],
  [644, 378],
  [643, 382],
  [638, 388],
  [622, 380],
  [645, 397],
  [659, 392],
  [696, 354],
  [695, 346],
  [688, 312],
  [728, 316],
  [715, 290],
  [725, 278],
  [693, 252],
  [760, 270],
  [838, 275],
  [849, 295],
  [844, 303],
  [859, 307],
  [1000, 282],
  [849, 315],
  [756, 324],
  [753, 361],
  [731, 361],
  [748, 383],
  [775, 423],
  [784, 428],
  [789, 426],
  [803, 425],
  [812, 420],
  [813, 436],
  [824, 450],
  [863, 445],
  [898, 456],
  [908, 453],
  [913, 441],
  [940, 450],
  [999, 453],
  [1021, 467],
  [1021, 498],
  [1122, 497],
  [1121, 558],
  [1143, 594],
  [1164, 596],
  [1175, 601],
  [1224, 606],
  [1206, 659],
  [1268, 737],
  [1279, 758],
  [1283, 761],
  [1286, 759],
  [1316, 742],
  [1396, 808],
  [1400, 807],
  [1424, 780],
  [1324, 733],
  [1339, 701],
  [1129, 473],
  [1137, 471],
  [1014, 516],
  [1005, 520],
  [1023, 533],
  [911, 471],
  [852, 508],
  [848, 541],
  [867, 436],
  [869, 431],
  [844, 481],
  [845, 485],
  [851, 490],
  [876, 428],
  [813, 414],
  [788, 438],
  [790, 460],
  [304, 364],
  [291, 354],
  [257, 315],
  [126, 257],
  [108, 180],
  [136, 296],
  [136, 300],
  [137, 318],
  [139, 321],
  [140, 330],
  [147, 349],
  [128, 379],
  [133, 380],
  [133, 391],
  [147, 397],
  [143, 399],
  [138, 404],
  [150, 413],
  [171, 436],
  [212, 455],
  [199, 470],
  [212, 473],
  [216, 472],
  [184, 368],
  [174, 382],
  [263, 433],
  [230, 411],
  [225, 469],
  [271, 512],
  [331, 558],
  [371, 520],
  [377, 522],
  [434, 569],
  [491, 613],
  [608, 531],
  [655, 534],
  [677, 535],
  [686, 540],
  [699, 542],
  [712, 624],
  [769, 672],
  [775, 679],
  [778, 683],
  [442, 673],
  [446, 679],
  [432, 707],
  [429, 711],
  [426, 717],
  [428, 721],
  [408, 724],
  [413, 730],
  [404, 735],
  [397, 742],
  [1166, 400],
  [1180, 395],
  [1175, 370],
  [1180, 411],
  [1188, 410],
  [1189, 414],
  [1193, 406],
  [1189, 401],
  [1193, 421],
  [1187, 450],
  [1200, 459],
  [1179, 451],
  [1175, 457],
  [1215, 391],
  [1211, 382],
  [1214, 379],
  [1239, 393],
  [1265, 335],
  [1237, 402],
];

export default starPoints;
