/**
 * Initializes any links that should trigger the Drift chat widget
 */
export function initChat() {
  if (typeof drift === 'undefined') {
    return false;
  }

  // Enhance elements with a '[data-open-chat="true"]' to open the drift chat bubble
  drift.on('ready', () => {
    const driftTriggers = [...document.querySelectorAll('[data-open-chat="true"]')];

    initChatTriggers(driftTriggers);
  });

  return false;
}

function initChatTriggers(triggers) {
  const driftChat = document.querySelector('body > .drift-frame-controller');

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
      const driftPlaybookId = parseInt(trigger.dataset.driftPlaybook, 10);

      event.preventDefault();

      driftChat.classList.add('is-above-modal');
      drift.api.startInteraction({interactionId: driftPlaybookId});
    });

    trigger.classList.add('js-is-initialized');
  });
}
