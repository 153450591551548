import {trapFocus} from '../utils/trapFocus';
import {userPrefersReducedMotion} from '../utils/userPrefersReducedMotion';
import {toggleScrollFreeze} from '../utils/scrollFreeze';

import {Accordion} from './accordions';

const dropdownTriggers = [...document.querySelectorAll('.js-navigation__sub-level-trigger')];
const desktopNav = document.querySelector('.js-desktop-navigation');
const mobileNav = document.querySelector('.js-mobile-navigation');
const mobileNavOverlay = document.querySelector('.js-mobile-navigation__overlay');

/**
 * Initializes the global navigation.
 */
export function initNavigation() {
  if (!document.querySelectorAll('.main-navigation').length > 0) {
    return false;
  }
  handleDropdownMenus();
  handleMobileNav();
  handleAccordions();
  return false;
}

/**
 * Adds event listeners to dropdown triggers and the document body
 */
function handleDropdownMenus() {
  dropdownTriggers.forEach((value, index) => {
    const trigger = dropdownTriggers[index];
    const dropdownMenu = document.getElementById(trigger.getAttribute('aria-controls'));

    trigger.addEventListener('click', () => {
      resetDropdowns(dropdownMenu);
      if (dropdownMenuIsOpen(dropdownMenu)) {
        closeDropdownMenu(dropdownMenu);
      } else {
        openDropdownMenu(dropdownMenu);
      }
    });

    trigger.addEventListener('mouseenter', () => {
      resetDropdowns(dropdownMenu);
      openDropdownMenu(dropdownMenu);
    });
  });

  document.addEventListener('keydown', handleKeyPress);
  document.addEventListener('mousedown', handleMouseDown);
}

/**
 * Returns true if the menu is open, false if closed
 * @param {Node} dropdownMenu Dropdown menu to check
 */
function dropdownMenuIsOpen(dropdownMenu) {
  return dropdownMenu.classList.contains('is-active');
}

/**
 * Closes all dropdowns and resets state
 * @param {Node} skipMenu Menu to skip closing
 */
function resetDropdowns(skipMenu) {
  dropdownTriggers.forEach((value, index) => {
    const dropdownMenu = document.getElementById(dropdownTriggers[index].getAttribute('aria-controls'));
    if (skipMenu === dropdownMenu) {
      return false;
    }
    dropdownTriggers[index].classList.remove('is-active');
    closeDropdownMenu(dropdownMenu);
    document.removeEventListener('keydown', handleKeyPress);
    return false;
  });
}

/**
 * Opens a dropdown menu
 * @param {Node} dropdownMenu The menu to open.
 */
function openDropdownMenu(dropdownMenu) {
  dropdownMenu.style.display = 'block';
  document.querySelector(`[aria-controls="${dropdownMenu.id}"]`).setAttribute('aria-expanded', 'true');

  // Wait for display to change
  setTimeout(() => {
    dropdownMenu.classList.add('is-active');
    document.addEventListener('keydown', handleKeyPress);
  }, 30);
}

/**
 * Closes a dropdown menu
 * @param {Node} dropdownMenu The menu to close
 */
function closeDropdownMenu(dropdownMenu) {
  document.querySelector(`[aria-controls="${dropdownMenu.id}"]`).setAttribute('aria-expanded', 'false');
  dropdownMenu.style.display = 'none';
  dropdownMenu.classList.remove('is-active');
  document.removeEventListener('keydown', handleKeyPress);
}

/**
 * Sets up the mobile navigation
 */
function handleMobileNav() {
  const triggers = [...document.querySelectorAll('.js-mobile-navigation__trigger')];

  triggers.forEach((value, index) => {
    triggers[index].addEventListener('click', () => {
      toggleMobileNav();
    });
  });
}

/**
 * Reverses the close/open state of the mobile nav
 */
function toggleMobileNav() {
  if (mobileNav.classList.contains('is-active')) {
    closeMobileNav();
  } else {
    openMobileNav();
  }
}

/**
 * Opens the mobile nav
 */
function openMobileNav() {
  toggleScrollFreeze();
  mobileNav.style.display = 'block';
  mobileNav.scrollTop = 0;
  mobileNavOverlay.style.display = 'block';

  // Wait for display to change
  setTimeout(() => {
    mobileNav.classList.add('is-active');
    mobileNavOverlay.classList.add('is-visible');
  }, 15);

  if (userPrefersReducedMotion()) {
    activateNav();
  } else {
    mobileNav.addEventListener('transitionend', activateNav);
  }

  function activateNav() {
    trapFocus(mobileNav);
    mobileNav.focus();
    document.addEventListener('keydown', handleKeyPress);
    mobileNav.removeEventListener('transitionend', activateNav);
  }
}

/**
 * Closes the mobile nav
 */
function closeMobileNav() {
  if (!mobileNav.classList.contains('is-active')) {
    return false;
  }
  toggleScrollFreeze();
  mobileNav.classList.remove('is-active');
  mobileNavOverlay.classList.remove('is-visible');
  document.querySelectorAll('.js-mobile-navigation__trigger')[0].focus();

  if (userPrefersReducedMotion()) {
    deactivateNav();
  } else {
    mobileNav.addEventListener('transitionend', deactivateNav);
  }

  function deactivateNav() {
    mobileNav.style.display = 'none';
    mobileNavOverlay.style.display = 'none';
    document.removeEventListener('keydown', handleKeyPress);
    mobileNav.removeEventListener('transitionend', deactivateNav);
  }
  return false;
}

/**
 * Reset the nav back to the default state
 */
function resetNav() {
  resetDropdowns();
  closeMobileNav();
}

/**
 * Sets up the accordions in the mobile nav
 */
function handleAccordions() {
  const accordions = [...document.querySelectorAll('.js-accordion')];

  accordions.forEach((value, index) => {
    new Accordion(accordions[index]);
  });
}

/**
 * Listens for esc and closes the nav
 */
function handleKeyPress(event) {
  if (event.keyCode === 27) {
    resetNav();
  }
}

/**
 * Closes nav items on mousedown
 * @param {Object} event The mouse down event
 */
function handleMouseDown(event) {
  if (
    desktopNav !== event.target &&
    mobileNav !== event.target &&
    !desktopNav.contains(event.target) &&
    !mobileNav.contains(event.target) &&
    !document.querySelectorAll('.js-modal.is-active').length
  ) {
    resetNav();
  }
}
