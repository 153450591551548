/**
 * Returns a random number between min and max, inclusive.
 * @param {number} min The random number floor
 * @param {number} max The random number ceiling
 * @param {number} decimalPlaces Number of decimal places to round to
 */
/* eslint-disable no-mixed-operators */
export function randomNumber(min = 1, max = 10, decimalPlaces = 0) {
  const rand = Math.random() < 0.5 ? (1 - Math.random()) * (max - min) + min : Math.random() * (max - min) + min;
  const power = 10 ** decimalPlaces;
  return Math.round(rand * power) / power;
}
/* eslint-enaable no-mixed-operators */
