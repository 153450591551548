/**
 * Draws the star on the canvas and controls positioning and radius
 * @param {integer} xCoordinate  The X coordinate for the center of the star
 * @param {integer} yCoordinate  The Y coordinate for the center of the star
 * @param {integer} radius  The desired size of the star
 * @param {string} color  The color that the star should be
 * @param {object} ctx  The canvas context
 */
export default class Star {
  constructor(xCoordinate, yCoordinate, radius, color, ctx) {
    this.xCoordinate = xCoordinate;
    this.yCoordinate = yCoordinate;
    this.radius = radius;
    this.amountToChange = 0.021;
    this.endAngle = 2 * Math.PI;
    this.color = color;
    this.ctx = ctx;
  }

  /**
   * Draws the star on the canvas based on its own properties
   */
  /* eslint-disable no-mixed-operators */
  draw() {
    this.ctx.beginPath();
    this.ctx.arc(
      this.xCoordinate + this.radius / 2,
      this.yCoordinate + this.radius / 2,
      this.radius,
      0,
      this.endAngle,
      false,
    );
    this.ctx.fillStyle = this.color;
    this.ctx.closePath();
    this.ctx.fill();
  }
  /* eslint-enable no-mixed-operators */

  /**
   * Updates the radius of the star
   */
  updateRadius() {
    if (this.radius >= 2 || this.radius < 0.1) {
      this.amountToChange = -this.amountToChange;
    }

    this.radius += this.amountToChange;
  }
}
