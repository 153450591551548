/**
 * Checks if an element is at least partially visible in the viewport
 * @param   {Node}      element The element to check
 * @return  {Boolean}  True if the element is at least partially visible, false if not
 */
export function isInViewport(element) {
  const currentScrollTop = window.pageYOffset;
  const currentScrollBottom = currentScrollTop + window.innerHeight;
  const elementOffset = element.getBoundingClientRect().top + window.pageYOffset;
  const elementHeight = element.getBoundingClientRect().bottom - element.getBoundingClientRect().top;

  if (currentScrollBottom >= elementOffset && currentScrollTop < elementOffset + elementHeight) {
    return true;
  } else if (currentScrollBottom < elementOffset || currentScrollTop > elementOffset + elementHeight) {
    return false;
  }

  return false;
}
